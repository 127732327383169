import { ActionTree } from 'vuex';
import RootState from '@/types/store/RootState';
import CommuneState from '@/types/store/CommuneState';
import CommuneProjection from '@/types/projections/CommuneProjection';
import CommuneApi from '@/app/api/CommuneApi';

const actions: ActionTree<CommuneState, RootState> = {
  async fetchCommunes({ commit, rootState }): Promise<CommuneProjection[]> {
    const data = await CommuneApi.getListOfSupportedCommunes();
    commit('setCommunes', data);
    return data;
  },
  async communeById({ state }, id): Promise<CommuneProjection> {
    return state.communes.find((x) => x.id == id) || ({ id: 2192, title: 'Fehmarn', vat: 19 } as CommuneProjection);
  },
};

export default actions;
