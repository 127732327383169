import { Module } from 'vuex';
import RootState from '@/types/store/RootState';
import DiscountState from '@/types/store/DiscountState';

import state from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const module: Module<DiscountState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default module;
