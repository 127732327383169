import { ActionTree } from 'vuex';
import RootState from '@/types/store/RootState';
import PaymentMethod from '@/types/checkout/PaymentMethod';
import CheckoutApi from '@/app/api/CheckoutApi';
import CheckoutState from '@/types/store/CheckoutState';
import BookTaxcardRequest from '@/types/checkout/BookTaxcardRequest';
import BookTaxcardResponse from '@/types/checkout/BookTaxcardResponse';
import BookingApi from '@/app/api/BookingApi';
import BookingPriceProjection from '@/types/checkout/BookingPriceProjection';
import LoginRequest from '@/types/checkout/LoginRequest';
import SessionApi from '@/app/api/SessionApi';
import LoginResponse from '@/types/checkout/LoginResponse';

const actions: ActionTree<CheckoutState, RootState> = {
  async fetchPaymentMethods({ commit }, communeId: number): Promise<PaymentMethod[]> {
    return CheckoutApi.getPaymentOptions(communeId);
  },
  async bookTaxCards({ commit, rootGetters }, bookingCommand: BookTaxcardRequest): Promise<BookTaxcardResponse> {
    let response;
    try {
      response = await BookingApi.bookTaxcards(bookingCommand);
    } catch (e) {
      const error = new Error('Failed to book taxcards');
      // @ts-ignore
      error.error = e;
      throw error;
    }
    const checkoutValues = rootGetters['steps/stepValuesById']('checkout');

    commit(
      'steps/setStepValues',
      { id: 'checkout', values: { ...checkoutValues, orderId: response.orderId } },
      { root: true }
    );
    return response;
  },
  async fetchCommuneDiscounts({ commit }, { communeId, taxcards }) {
    const ticketDiscounts: any[] = [];
    let index = 0;
    for (const taxcard of taxcards) {
      try {
        const discounts = await BookingApi.fetchCommuneDiscounts(communeId, taxcard);
        if (!taxcard.option) {
          ticketDiscounts[index] = null;
          return;
        }

        const ticketDiscountOptionId = Object.keys(taxcard.option)[0];
        if (!ticketDiscountOptionId) {
          ticketDiscounts[index] = null;
          return;
        }
        const discount = discounts.find((d) => d.id === parseInt(ticketDiscountOptionId, 10));
        if (!discount) {
          ticketDiscounts[index] = null;
          return;
        }
        ticketDiscounts[index] = discount;
      } catch (e) {
        ticketDiscounts[index] = null;
      }
      index++;
    }

    return ticketDiscounts;
  },
  async fetchBookingPrice({ commit }, bookingCommand: BookTaxcardRequest): Promise<BookingPriceProjection> {
    return await BookingApi.fetchBookingPrice(bookingCommand);
  },
  async doLogin({ state, commit }, loginCommand: LoginRequest): Promise<LoginResponse> {
    const loginResponse = await SessionApi.doLogin(loginCommand);
    if (loginResponse.hasUser()) {
      state.user = loginResponse;
    } else {
      state.user = null;
    }

    return loginResponse;
  },
  async doLogout({ state, commit }) {
    state.user = null;
    return await SessionApi.doLogout();
  },
  async saveRegistrationForm({ state }, { signature, passportNumber, orderId }) {
    return await BookingApi.saveRegistrationForm(orderId, signature, passportNumber);
  },
};

export default actions;
