import FieldDefinitionType from '@/types/steps/FieldDefinitionType';

const getDefaultValueByFieldType = (type: FieldDefinitionType): string | number | boolean | null | undefined => {
  switch (type) {
    case 'text':
    case 'select':
    case 'password':
      return '';
    case 'number':
      return 0;
    case 'date':
    case 'birthday':
    case 'buttonToggle':
      return null;
    case 'checkbox':
      return false;
  }
};

export default getDefaultValueByFieldType;
