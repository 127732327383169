import { RouteConfig } from 'vue-router/types/router';
import steps from '@/app/steps';
import generateStepRoutes from '@/router/generateStepRoutes';

const routes: RouteConfig[] = [
  ...generateStepRoutes(steps),
  {
    name: 'paymentOptIn',
    path: `/paymentOptIn`,
    component: () => import(`@/views/steps/PaymentOptInStep.vue`),
    props: (route) => ({
      step: { id: 'paymentOptIn', initialValues: { paymentType: null }, values: { paymentType: null } },
    }),
  },
];

export default routes;
