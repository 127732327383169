const apiUrl = process.env.VUE_APP_API_BASEURL;

const ApiRoutes = {
  messages: `${apiUrl}/v2/commune/:communeId/i18n/locale/:locale/translation`,
  communesByApiKey: `${apiUrl}/v2/communeByApiKey/`,
  startPayment: `${apiUrl}/v2/checkout/startPayment/:orderId`,
  checkoutListPaymentMethods: `${apiUrl}/v2/checkout/:communeId/paymentMethod/`,
  destroySession: `${apiUrl}/v2/auth/session/destroy`,
  bookTaxCards: `${apiUrl}/v2/booking`,
  fetchBookingPrice: `${apiUrl}/v2/booking/price`,
  fetchCommuneDiscounts: `${apiUrl}/v2/commune/:communeId/discount/`,
  searchAccommodations: `${apiUrl}/v2/accommodation/search/:communeId/:query`,
  login: `${apiUrl}/v2/auth/login/bookingwidget`,
  logout: `${apiUrl}/session`,
  widgetConfiguration: `${apiUrl}/v2/widget/configurationByApiKey`,
  saveRegistrationForm: `${apiUrl}/v2/booking/:orderId/registrationForm`,
  getPreparePrintId: (transactionId: string, taxcards: string) =>
    `${apiUrl}/v2/print/prepare/${transactionId}/${taxcards}`,
  getPrintPDF: (printId: string, fileName: string) => `${apiUrl}/v2/print/${printId}/${fileName}.pdf?display=true`,
  directPayment: `${apiUrl}/v2/checkout/direct`,
  verifyPayment: `${apiUrl}/v2/checkout/verifyPayment`,
  freeCheckout: `${apiUrl}/v2/checkout/freeCheckout`,
};
export default ApiRoutes;
