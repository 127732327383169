import { GetterTree } from 'vuex';
import RootState from '@/types/store/RootState';
import CommuneState from '@/types/store/CommuneState';
import CommuneProjection from '@/types/projections/CommuneProjection';

const getters: GetterTree<CommuneState, RootState> = {
  communes(state): CommuneProjection[] {
    return state.communes;
  },
};

export default getters;
