import StepDefinition from '@/types/steps/StepDefinition';
import generateInitialValuesByStepFields from '@/store/modules/steps/helper/generateInitialValuesByStepFields';

const generateInitialValuesBySteps = (steps?: StepDefinition[]) => {
  if (!steps || steps.length === 0) {
    return;
  }

  const initialValues: any = [{}];

  steps.forEach(({ id, fields }) => {
    initialValues[0][id] = {
      initialValues: generateInitialValuesByStepFields(fields),
      values: generateInitialValuesByStepFields(fields),
    };
  });

  return initialValues;
};

export default generateInitialValuesBySteps;
