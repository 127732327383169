import CheckoutState from '@/types/store/CheckoutState';
import LoginResponse from '@/types/checkout/LoginResponse';

const state: CheckoutState = {
  currentPriceProjection: null,
  currentTicketDiscounts: [],
  taxcards: [],
  commune: null,
  user: null,
};

export default state;
