import { ActionTree } from 'vuex';
import AccommodationState from '@/types/store/AccommodationState';
import RootState from '@/types/store/RootState';
import AccommodationProjection from '@/types/projections/AccommodationProjection';
import AccommodationApi from '@/app/api/AccommodationApi';

const actions: ActionTree<AccommodationState, RootState> = {
  resetSearchAccommodations({ commit }) {
    commit('setAccommodations', []);
  },
  searchAccommodations({ state, commit, rootState }, { query, communeId }): Promise<AccommodationProjection[]> {
    return AccommodationApi.search(communeId, query).then((data) => {
      commit('setAccommodations', data);
      return data;
    });
  },
};

export default actions;
