export default class PaymentMethod {
  public name: string;

  constructor(paymentMethod: string) {
    this.name = paymentMethod;
  }

  public getLowerCase() {
    return this.name.toLowerCase();
  }

  public getImage() {
    return `payment-provider/${this.getLowerCase()}.svg`;
  }
}
