export const defaultHeader = {
  'Content-Type': 'application/json',
} as { [k: string]: any };

const checkStatus = async (response: Response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new Error(response.statusText);
    const data = await response.json();
    // @ts-ignore
    error.response = response;
    // @ts-ignore
    error.response.data = data;
    throw error;
  }
};

const parseJSON = (response: Response) => response.json();

export const request = <T>(url: string, config: RequestInit): Promise<T> =>
  fetch(url, config).then(checkStatus).then(parseJSON);

export const get = <T>(url: string, headers?: Headers): Promise<T> => {
  return request<T>(url, { method: 'GET', credentials: 'include', headers: { ...defaultHeader, ...headers } });
};

export const post = <T>(url: string, data: { [k: string]: any }, headers?: Headers): Promise<T> => {
  return request<T>(url, {
    method: 'POST',
    credentials: 'include',
    headers: { ...defaultHeader, ...headers },
    body: JSON.stringify(data),
  });
};

export const getRequest = function (url: string): Promise<Response> {
  return fetch(url, { method: 'GET', credentials: 'include', headers: defaultHeader });
};

export const postRequest = function (url: string, data: { [k: string]: string } = {}): Promise<Response> {
  return fetch(url, { method: 'POST', credentials: 'include', headers: defaultHeader, body: JSON.stringify(data) });
};

export const deleteRequest = function (url: string, data: { [k: string]: string } = {}): Promise<Response> {
  return fetch(url, { method: 'DELETE', credentials: 'include', headers: defaultHeader, body: JSON.stringify(data) });
};
