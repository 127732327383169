import { MutationTree } from 'vuex';
import AccommodationState from '@/types/store/AccommodationState';
import AccommodationProjection from '@/types/projections/AccommodationProjection';

const mutations: MutationTree<AccommodationState> = {
  setAccommodations(state, accommodations: AccommodationProjection[]): void {
    state.accommodations = accommodations;
  },
};

export default mutations;
