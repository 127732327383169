import RootState from '@/types/store/RootState';
import { MutationTree } from 'vuex';
import { defaultHeader } from '@/app/core/request';

export type setApiKeyFn = (apiKey: string) => void;
export type setLanguageFn = (language: string) => void;
export type setOfflinePaymentFn = (offlinePayment: boolean) => void;

const mutations: MutationTree<RootState> = {
  setApiKey(state, apiKey: string) {
    defaultHeader['Authorization'] = `Bearer ${apiKey}`;
    state.apiKey = apiKey;
  },
  setLanguage(state, language: string) {
    state.language = language;
  },
  setOfflinePayment(state, offlinePayment: boolean) {
    state.offlinePayment = offlinePayment;
  },
};

export default mutations;
