import RootState from '@/types/store/RootState';
import { GetterTree } from 'vuex';

const getters: GetterTree<RootState, RootState> = {
  offlinePayment(state) {
    return state.offlinePayment;
  },
};

export default getters;
