import FieldDefinition from '@/types/steps/FieldDefinition';
import getDefaultValueByFieldType from '@/store/modules/steps/helper/getDefaultValueByFieldType';

const generateInitialValuesByStepFields = (fields?: FieldDefinition[]) => {
  const initialValues: any = {};

  if (fields) {
    fields.forEach((f) => {
      initialValues[f.id] = getDefaultValueByFieldType(f.type);
    });
  }

  return initialValues;
};

export default generateInitialValuesByStepFields;
