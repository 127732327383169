import { GetterTree } from 'vuex';
import StepsState from '@/types/store/StepsState';
import RootState from '@/types/store/RootState';
import currentStep from '@/store/modules/steps/getters/currentStep';
import nextStep from '@/store/modules/steps/getters/nextStep';
import stepById from '@/store/modules/steps/getters/stepById';
import intermediateTaxcards from '@/store/modules/steps/getters/intermediateTaxcards';
import intermediateTaxcardByIndex from '@/store/modules/steps/getters/intermediateTaxcardByIndex';
import stepValuesById from '@/store/modules/steps/getters/stepValuesById';
import subStepValuesById from '@/store/modules/steps/getters/subStepValuesById';
import selectedCommune from '@/store/modules/steps/getters/selectedCommune';
import parentalAllowanceNeeded from '@/store/modules/steps/getters/parentalAllowanceNeeded';
import prevStep from '@/store/modules/steps/getters/prevStep';
import guestTypeByIteration from '@/store/modules/steps/getters/guestTypeByIteration';

const getters: GetterTree<StepsState, RootState> = {
  currentStep,
  nextStep,
  prevStep,
  stepById,
  intermediateTaxcards,
  intermediateTaxcardByIndex,
  stepValuesById,
  subStepValuesById,
  selectedCommune,
  parentalAllowanceNeeded,
  guestTypeByIteration,
};

export default getters;
