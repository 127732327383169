import StepDefinition from '@/types/steps/StepDefinition';
import {
  CHECKOUT_STEATUS_STEP,
  CHECKOUT_STEP,
  COMMUNE_STEP,
  LOGIN_STEP,
  PAYMENT_OPT_IN,
  OVERVIEW_STEP,
  TAXCARD_ACCOMMODATION_DATA_STEP,
  TAXCARD_ADDRESS_DATA_STEP,
  TAXCARD_CONTACT_DATA_STEP,
  TAXCARD_GUEST_TYPE_STEP,
  TAXCARD_OPTION_DATA_STEP,
  TAXCARD_BOOKING_PERSON_STEP,
  TAXCARD_PETS_STEP,
  TAXCARD_STAY_DATA_STEP,
  TAXCARD_STEP,
  TAXCARD_USER_DATA_STEP,
} from '@/app/constants/StepIds';

const steps: StepDefinition[] = [
  {
    id: COMMUNE_STEP,
    fields: [{ id: 'commune', type: 'select', mandatory: true }],
  },
  {
    id: TAXCARD_STEP,
    steps: [
      {
        id: TAXCARD_GUEST_TYPE_STEP,
        fields: [{ id: 'guestType', type: 'select', mandatory: true }],
      },
      {
        id: TAXCARD_BOOKING_PERSON_STEP,
        fields: [
          { id: 'anonymous', type: 'checkbox', mandatory: false },
          { id: 'persons', type: 'number', mandatory: false },
        ],
      },
      {
        id: TAXCARD_USER_DATA_STEP,
        fields: [
          { id: 'salutation', type: 'select', mandatory: true },
          { id: 'title', type: 'select', mandatory: false },
          { id: 'firstName', type: 'text', mandatory: true },
          { id: 'lastName', type: 'text', mandatory: true },
          { id: 'birthday', type: 'birthday', mandatory: { overnight: true, day: false } },
        ],
      },
      {
        id: TAXCARD_CONTACT_DATA_STEP,
        fields: [
          { id: 'phone', type: 'text', mandatory: { overnight: false, day: false } },
          { id: 'mail', type: 'text', mandatory: { overnight: true, day: true } },
        ],
      },
      {
        id: TAXCARD_ADDRESS_DATA_STEP,
        fields: [
          { id: 'addressStreetAndNumber', type: 'text', mandatory: { overnight: true, day: false } },
          { id: 'addressZip', type: 'text', mandatory: { overnight: true, day: false } },
          { id: 'addressCity', type: 'text', mandatory: { overnight: true, day: false } },
          { id: 'addressCountry', type: 'select', mandatory: { overnight: true, day: false } },
        ],
      },
      {
        id: TAXCARD_PETS_STEP,
        fields: [{ id: 'pets', type: 'number', mandatory: false }],
      },
      {
        id: TAXCARD_STAY_DATA_STEP,
        fields: [
          { id: 'stayBegin', type: 'date', mandatory: true },
          { id: 'stayEnd', type: 'date', mandatory: true },
        ],
      },
      { id: TAXCARD_ACCOMMODATION_DATA_STEP },
      { id: TAXCARD_OPTION_DATA_STEP },
    ],
  },
  { id: OVERVIEW_STEP },
  {
    id: LOGIN_STEP,
    fields: [
      { id: 'username', type: 'text', mandatory: false },
      { id: 'password', type: 'password', mandatory: false },
    ],
  },
  // { id: PAYMENT_OPT_IN, fields: [{ id: 'paymentType', type: 'buttonToggle', mandatory: true }] },
  {
    id: CHECKOUT_STEP,
    fields: [
      { id: 'paymentMethod', type: 'text', mandatory: false },
      { id: 'tacAccepted', type: 'checkbox', mandatory: true },
      { id: 'acceptedPrivacyTerms', type: 'checkbox', mandatory: true },
      { id: 'adultPermissionGranted', type: 'checkbox', mandatory: false },
      { id: 'orderId', type: 'text', mandatory: false },
    ],
  },
  { id: CHECKOUT_STEATUS_STEP },
];

export const addStepAfter = (id: string, step: StepDefinition): void => {
  const index = steps.findIndex((s) => s.id === id);
  if (!index) {
    return;
  }
  steps.splice(index + 1, 0, step);
};

export default steps;
