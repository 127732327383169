import Taxcard from '@/types/taxcard/Taxcard';
import { Getter } from 'vuex';
import StepsState from '@/types/store/StepsState';
import RootState from '@/types/store/RootState';

export type intermediateTaxcardByIndexFn = (index: number) => Taxcard | undefined;
const intermediateTaxcardByIndex: Getter<StepsState, RootState> = (state, getters) => (index: number) => {
  const taxcards = getters.intermediateTaxcards;
  if (taxcards && taxcards.length > index) {
    return taxcards[index];
  }
  return;
};
export default intermediateTaxcardByIndex;
