import getDefaultValueByFieldType from '@/store/modules/steps/helper/getDefaultValueByFieldType';
import StepDefinition from '@/types/steps/StepDefinition';

const validateStep = (
  stepDefinition: StepDefinition,
  step: any,
  valuesType: 'values' | 'initialValues' = 'values'
): boolean => {
  const fieldDefinitions = stepDefinition.fields;
  if (!fieldDefinitions) {
    return true;
  }

  let fieldsValid = true;

  fieldDefinitions.forEach(({ id, type: fieldType, mandatory }) => {
    if (!fieldsValid) {
      return;
    }
    if (mandatory && step[valuesType][id] === getDefaultValueByFieldType(fieldType)) {
      fieldsValid = false;
    }
  });

  return fieldsValid;
};

export default validateStep;
