
















import VueIFrame from '@/components/VueIFrame.vue';
import { Component, Prop, Provide, Vue } from 'vue-property-decorator';
import StoreInitializer from '@/components/initializer/StoreInitializer.vue';
import VueIFrameConfig from '@/types/widget/VueIFrameConfig';
import I18nInitializer from '@/components/i18n/I18nInitializer.vue';
import BookingProcess from '@/views/BookingProcess.vue';
import BookingOptionTaxcard from '@/types/widget/BookingOptionTaxcard';
import { namespace } from 'vuex-class';

@Component({ components: { BookingProcess, I18nInitializer, StoreInitializer, VueIFrame } })
export default class App extends Vue {
  @Prop()
  public router: any;

  @Prop()
  public store: any;

  // vue options for the "inner" root instance inside the iFrame
  @Provide()
  public vueIFrameConfig: VueIFrameConfig = { router: this.router, store: this.store };

  @Prop(String)
  public apiKey!: string;

  @Prop(String)
  public language!: string;

  @Prop({ type: String, default: '500px' })
  public height!: string;

  @Prop({ type: String, default: '100%' })
  public width!: string;

  @Prop(Number)
  public communeId!: number;

  @Prop(Array)
  public taxcards!: BookingOptionTaxcard[];

  @Prop(Boolean)
  public offlinePayment!: boolean;

  addStylesToDocument(document: HTMLDocument) {
    // const link = document.createElement('link');
    // link.href = `${process.env.VUE_APP_CDN_HOST}/welcmpass-booking.css`;
    // link.type = 'text/css';
    // link.rel = 'stylesheet';
    // link.media = 'screen,print';
    // document.head.appendChild(link);
  }
}
