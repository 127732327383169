import LoginRequest from '@/types/checkout/LoginRequest';
import { deleteRequest, postRequest } from '@/app/core/request';
import apiRoutes from '@/app/config/api-routes';
import LoginResponse from '@/types/checkout/LoginResponse';

class SessionApi {
  async doLogin(command: LoginRequest): Promise<LoginResponse> {
    try {
      const response = await postRequest(apiRoutes.login, command as any);

      const body = (await response.json()) as any;
      if (response.status === 403) {
        await postRequest(apiRoutes.destroySession, { code: body.code });
        const responseSecondLogin = await postRequest(apiRoutes.login, command as any);
        const bodySecondLogin = (await responseSecondLogin.json()) as any;
        return new LoginResponse(
          bodySecondLogin.user.firstName,
          bodySecondLogin.user.lastName,
          'login.multiSession.dialog.body'
        );
      }

      if (response.status !== 200) {
        return new LoginResponse(null, null, 'booking.login.error.dialog.body');
      }

      return new LoginResponse(body.user.firstName, body.user.lastName, null);
    } catch (e) {
      return new LoginResponse(null, null, e.data.message);
    }
  }

  async doLogout() {
    await deleteRequest(apiRoutes.logout);
  }
}

export default new SessionApi();
