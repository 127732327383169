import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import BookingOptions from '@/types/widget/BookingOptions';
import BookingOptionTaxcard from '@/types/widget/BookingOptionTaxcard';
import steps, { addStepAfter } from '@/app/steps';
import { LOGIN_STEP, PAYMENT_OPT_IN } from '@/app/constants/StepIds';

const getElementOrFail = (querySelector: string): HTMLElement => {
  const element = document.querySelector(querySelector);
  if (!(element instanceof HTMLElement)) {
    throw new Error('WELCMpass Booking: Container not found.');
  }
  if (!element) {
    throw new Error('WELCMpass Booking Widget: Unable to mount application. Container was not found');
  }
  return element;
};

const getPropsDataByOptions = (options: BookingOptions | string): BookingOptions => {
  if (typeof options === 'string') {
    return { apiKey: options };
  } else {
    return { ...options };
  }
};

export default class BookingWidget {
  private readonly container: HTMLElement;
  private readonly appInstance: Vue;

  private constructor(container: HTMLElement, appInstance: Vue) {
    this.container = container;
    this.appInstance = appInstance;

    this.appInstance.$mount(this.container);
  }

  public setLanguage(language: string) {
    this.appInstance.$props.language = language;
  }

  public setTaxcards(taxcards: BookingOptionTaxcard[]) {
    this.appInstance.$props.taxcards = taxcards;
  }

  public setCommuneId(id: number) {
    this.appInstance.$props.communeId = id;
  }

  public addTaxcard(taxcard: BookingOptionTaxcard) {
    this.appInstance.$props.taxcards.push(taxcard);
  }

  public static render(querySelector: string, apiKey: string): BookingWidget;
  public static render(querySelector: string, options: BookingOptions | string): BookingWidget {
    const container = getElementOrFail(querySelector);
    const appClass = Vue.extend(App);

    Vue.config.productionTip = false;

    if (typeof options === 'object' && options.offlinePayment) {
      addStepAfter(LOGIN_STEP, {
        id: PAYMENT_OPT_IN,
        fields: [{ id: 'paymentType', type: 'buttonToggle', mandatory: true }],
      });
    }

    return new BookingWidget(
      container,
      new appClass({
        propsData: { ...getPropsDataByOptions(options), router, store },
      })
    );
  }
}
