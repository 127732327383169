






import { Vue, Component, Watch } from 'vue-property-decorator';
import StepDefinition from '@/types/steps/StepDefinition';
import { namespace } from 'vuex-class';
import { Route } from 'vue-router';
import WELCMpassRouter from '@/app/router/WELCMpassRouter';
import { COMMUNE_STEP } from '@/app/constants/StepIds';

const Steps = namespace('steps');

const initialStepId = COMMUNE_STEP;

@Component({})
export default class BookingProcess extends Vue {
  @Steps.Getter
  public currentStep!: StepDefinition;

  @Steps.Action
  public changeCurrentStep!: Function;

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(newVal: Route) {
    // Some action
    //
  }

  created() {
    this.changeCurrentStep(initialStepId); // todo should be calculated through step validation instead of a constant value
    const currentStep = this.currentStep;
    if (currentStep) {
      // WELCMpassRouter.go({ name: currentStep.id });
    }
  }
}
