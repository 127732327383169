import { Getter } from 'vuex';
import StepsState from '@/types/store/StepsState';
import RootState from '@/types/store/RootState';
import GuestType from '@/types/widget/GuestType';

export type guestTypeByIterationFn = (iteration: number) => GuestType;
const guestTypeByIteration: Getter<StepsState, RootState> = (state, getters) => (iteration: number) =>
  getters.subStepValuesById('taxcard', 'taxcardGuestType', iteration).guestType;

export default guestTypeByIteration;
