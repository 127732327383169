import { Module } from 'vuex';
import RootState from '@/types/store/RootState';

import actions from './actions';
import state from './state';
import getters from './getters';
import mutations from './mutations';
import CheckoutState from '@/types/store/CheckoutState';

const module: Module<CheckoutState, RootState> = {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};

export default module;
