import steps from '@/app/steps';
import StepsState from '@/types/store/StepsState';
import generateSteps from '@/store/modules/steps/helper/generateSteps';

const state: StepsState = {
  currentStepId: '',
  ...generateSteps(steps),
};

export default state;
