declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [k: string]: any;
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function (name: string): { [k: string]: any } {
  if (!(name in window)) {
    Object.defineProperty(window, name, {
      enumerable: true,
      configurable: true,
      writable: true,
    });
    window[name] = {};
  }

  return window[name];
}
