import { Getter } from 'vuex';
import StepsState from '@/types/store/StepsState';
import RootState from '@/types/store/RootState';
import stepById from '@/store/modules/steps/getters/stepById';

export type stepValuesByIdFn = (id: string) => any;
const stepValuesById: Getter<StepsState, RootState> = (...params) => (id: string) => {
  const step = stepById(...params)(id);
  return step['values'];
};

export default stepValuesById;
