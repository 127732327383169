export default class LoginResponse {
  public firstname: string | null;
  public lastname: string | null;
  public errortext: string | null;

  constructor(firstname: string | null, lastname: string | null, errortext: string | null) {
    this.firstname = firstname;
    this.lastname = lastname;
    this.errortext = errortext;
  }

  public hasError(): boolean {
    return this.errortext != null;
  }

  public hasUser(): boolean {
    return this.firstname != null || this.lastname != null;
  }
}
