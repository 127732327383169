import StepDefinition from '@/types/steps/StepDefinition';
import { Getter } from 'vuex';
import StepsState from '@/types/store/StepsState';
import RootState from '@/types/store/RootState';
import { getStepBeforeId } from '@/app/steps/StepUtils';

export type prevStepFn = (id: string) => StepDefinition | undefined;
const prevStep: Getter<StepsState, RootState> = (state) => {
  return getStepBeforeId(state.currentStepId);
};

export default prevStep;
