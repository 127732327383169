import { GetterTree } from 'vuex';
import RootState from '@/types/store/RootState';
import AccommodationState from '@/types/store/AccommodationState';
import AccommodationProjection from '@/types/projections/AccommodationProjection';

const getters: GetterTree<AccommodationState, RootState> = {
  accommodations(state): AccommodationProjection[] {
    return state.accommodations;
  },
};

export default getters;
