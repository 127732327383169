import { MutationTree } from 'vuex';
import CommuneState from '@/types/store/CommuneState';
import CommuneProjection from '@/types/projections/CommuneProjection';

const mutations: MutationTree<CommuneState> = {
  setCommunes(state, communes: CommuneProjection[]): void {
    state.communes = communes;
  },
};

export default mutations;
