import { MutationTree } from 'vuex';
import DiscountState from '@/types/store/DiscountState';
import PriceDiscountOptionProjection from '@/types/projections/PriceDiscountOptionProjection';

const mutations: MutationTree<DiscountState> = {
  addDiscounts(state, discounts: PriceDiscountOptionProjection[]): void {
    state.discounts = discounts.reduce((acc, val) => {
      acc[val.id] = val;
      return acc;
    }, state.discounts);
  },
};

export default mutations;
