import generateInitialValuesBySteps from '@/store/modules/steps/helper/generateInitialValuesBySteps';
import generateInitialValuesByStepFields from '@/store/modules/steps/helper/generateInitialValuesByStepFields';
import StepDefinition from '@/types/steps/StepDefinition';

const generateSteps = (steps: StepDefinition[]) => {
  const partialState: { [k: string]: any } = {};

  steps.forEach(({ id, steps, fields }) => {
    if (steps && steps.length > 0) {
      const initialValues = generateInitialValuesBySteps(steps);
      partialState[id] = {
        values: [],
        initialValues: [],
        initialValuesTemplate: JSON.parse(JSON.stringify(initialValues[0])),
      };
    } else {
      partialState[id] = {
        values: generateInitialValuesByStepFields(fields),
        initialValues: generateInitialValuesByStepFields(fields),
      };
    }
  });

  return partialState;
};

export default generateSteps;
