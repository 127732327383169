import { RawLocation, Route, VueRouter } from 'vue-router/types/router';

class WELCMpassRouter {
  private router?: VueRouter;
  private locationStack: RawLocation[] = [];

  public registerRouter(router: VueRouter): void {
    this.router = router;
  }

  public async back(): Promise<Route | void> {
    const router = this.getRouterOrFail();
    this.locationStack.pop();
    const location = this.locationStack.slice(-1).pop();
    if (!location) {
      return;
    }
    return router.replace(location);
  }

  public async go(location: RawLocation): Promise<Route | void> {
    const router = this.getRouterOrFail();
    this.locationStack.push(location);
    return router.push(location);
  }

  private getRouterOrFail(): VueRouter {
    if (!this.router) {
      throw new Error('Please register router first in WELCMpassRouter');
    }

    return this.router;
  }
}

export default new WELCMpassRouter();
