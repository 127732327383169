import { ActionTree } from 'vuex';
import DiscountState from '@/types/store/DiscountState';
import RootState from '@/types/store/RootState';
import Taxcard from '@/types/taxcard/Taxcard';
import BookingApi from '@/app/api/BookingApi';
import PriceDiscountOptionProjection from '@/types/projections/PriceDiscountOptionProjection';

interface FetchAndCacheDiscountPayload {
  communeId: number;
  taxcard: Taxcard;
}
export type fetchAndCacheDiscountsFn = (
  payload: FetchAndCacheDiscountPayload
) => Promise<PriceDiscountOptionProjection[]>;

const actions: ActionTree<DiscountState, RootState> = {
  async fetchAndCacheDiscounts(
    { state, commit, rootState },
    { communeId, taxcard }: FetchAndCacheDiscountPayload
  ): Promise<PriceDiscountOptionProjection[]> {
    const discounts = await BookingApi.fetchCommuneDiscounts(communeId, taxcard);
    commit('addDiscounts', discounts);
    return discounts;
  },
};

export default actions;
