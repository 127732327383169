import { MutationTree } from 'vuex';
import CheckoutState from '@/types/store/CheckoutState';
import BookingPriceProjection from '@/types/checkout/BookingPriceProjection';
import Taxcard from '@/types/taxcard/Taxcard';
import CommuneProjection from '@/types/projections/CommuneProjection';

const mutations: MutationTree<CheckoutState> = {
  setCurrentPriceProjection(state, prices: BookingPriceProjection | null): void {
    state.currentPriceProjection = prices;
  },
  setCurrentTicketDiscounts(state, ticketDiscounts: Array<any> | null): void {
    state.currentTicketDiscounts = ticketDiscounts;
  },
  setTaxcards(state, taxcards: Array<Taxcard>): void {
    state.taxcards = taxcards;
  },
  setCommune(state, commune: CommuneProjection | null): void {
    state.commune = commune;
  },
};

export default mutations;
