import { Getter } from 'vuex';
import StepsState from '@/types/store/StepsState';
import RootState from '@/types/store/RootState';
import moment, { Moment } from 'moment';

const parentalAllowanceNeeded: Getter<StepsState, RootState> = (state, getters) => {
  const taxcards = getters.stepValuesById('taxcard') || [];

  return taxcards
    .map((t: any) => t.taxcardUserData && t.taxcardUserData.values && t.taxcardUserData.values.birthday)
    .filter((birthday: Moment | undefined | null) => birthday && birthday.isValid())
    .map((birthday: Moment) => moment().diff(birthday, 'year'))
    .some((age: number) => age <= 14);
};

export default parentalAllowanceNeeded;
