export const COMMUNE_STEP = 'commune';
export const TAXCARD_STEP = 'taxcard';
export const TAXCARD_GUEST_TYPE_STEP = 'taxcardGuestType';
export const TAXCARD_USER_DATA_STEP = 'taxcardUserData';
export const TAXCARD_CONTACT_DATA_STEP = 'taxcardContactData';
export const TAXCARD_ADDRESS_DATA_STEP = 'taxcardAddressData';
export const TAXCARD_BOOKING_PERSON_STEP = 'taxcardBookingPersons';
export const TAXCARD_PETS_STEP = 'taxcardPets';
export const TAXCARD_STAY_DATA_STEP = 'taxcardStayData';
export const TAXCARD_ACCOMMODATION_DATA_STEP = 'taxcardAccommodationData';
export const TAXCARD_OPTION_DATA_STEP = 'taxcardOptionData';
export const OVERVIEW_STEP = 'overview';
export const LOGIN_STEP = 'login';
export const PAYMENT_OPT_IN = 'paymentOptIn';
export const CHECKOUT_STEP = 'checkout';
export const CHECKOUT_STEATUS_STEP = 'checkoutStatus';
