import { ActionTree } from 'vuex';
import RootState from '@/types/store/RootState';
import I18nState from '@/types/store/i18n/I18nState';
import I18nApi from '@/app/api/I18nApi';

const config = {
  withCredentials: true,
};

const actions: ActionTree<I18nState, RootState> = {
  switchLocale({ dispatch, commit }, locale: string | null): void {
    commit('setLocale', locale);
  },
  fetchTranslations({ commit, rootState, getters }): Promise<void> {
    const locale = getters.locale;
    const communeId = /*rootState.communeSelected || */ 0;
    return I18nApi.getTranslations(communeId, locale).then((data) => commit('setTranslations', data));
  },
};

export default actions;
