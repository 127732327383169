import I18nState from '@/types/store/i18n/I18nState';

let lang = localStorage.getItem('welcmLanguage');
if (!lang) {
  const systemLanguage = navigator.language;
  if (systemLanguage && systemLanguage.length) lang = systemLanguage.split('-')[0];
}

const state: I18nState = {
  locale: lang || null,
  translations: {},
};

export default state;
