import { Mutation, MutationTree } from 'vuex';
import Vue from 'vue';
import StepsState from '@/types/store/StepsState';
import setDynamicStepValues from '@/store/modules/steps/helper/setDynamicStepValues';

export type setStepInitialValuesFn = (options: { id: string; values: any }) => void;
const setStepInitialValues: Mutation<StepsState> = (state, { id, values }) => {
  setDynamicStepValues(state, 'initialValues', id, values);
};

export type setStepValuesFn = (options: { id: string; values: any }) => void;
const setStepValues: Mutation<StepsState> = (state, { id, values }) =>
  setDynamicStepValues(state, 'values', id, values);

export type setDeepStepInitialValuesFn = (options: {
  parentId: string;
  id: string;
  index: number;
  values: any;
}) => void;
const setDeepStepInitialValues: Mutation<StepsState> = (state, { parentId, id, index, values }) => {
  if (
    !state[parentId] ||
    !state[parentId]['initialValues'] ||
    !state[parentId]['initialValues'][index] ||
    !state[parentId]['initialValues'][index][id]
  ) {
    return;
  }
  Vue.set(state[parentId]['initialValues'][index][id], 'initialValues', values);
};

export type setDeepStepValuesFn = (options: { parentId: string; id: string; index: number; values: any }) => void;
const setDeepStepValues: Mutation<StepsState> = (state, { parentId, id, index, values }) => {
  if (
    !state[parentId] ||
    !state[parentId]['values'] ||
    !state[parentId]['values'][index] ||
    !state[parentId]['values'][index][id]
  ) {
    return;
  }
  Vue.set(state[parentId]['values'][index][id], 'values', values);
};

export type addInitialValuesFn = (options: { id: string }) => void;
const addInitialValues: Mutation<StepsState> = (state, { id }) => {
  const initialValues = JSON.parse(JSON.stringify(state[id].initialValuesTemplate));
  state[id].initialValues.push(initialValues);
  state[id].values.push(initialValues);
};

export type removeFromInitialValuesFn = (options: { id: string; index: number }) => void;
const removeFromInitialValues: Mutation<StepsState> = (state, { id, index }) => {
  state[id].initialValues.splice(index, 1);
};

export type removeFromValuesFn = (options: { id: string; index: number }) => void;
const removeFromValues: Mutation<StepsState> = (state, { id, index }) => {
  state[id].values.splice(index, 1);
};

export type addStepFn = (step: any) => void;
const addStep: Mutation<StepsState> = (state, { id, ...step }) => {
  state[id] = step;
};

const mutations: MutationTree<StepsState> = {
  setStepInitialValues,
  setStepValues,
  setDeepStepInitialValues,
  setDeepStepValues,
  addInitialValues,
  removeFromInitialValues,
  removeFromValues,
  addStep,
  setCurrentStepId(state, id: string) {
    state.currentStepId = id;
  },
};

export default mutations;
