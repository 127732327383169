import { get } from '@/app/core/request';
import apiRoutes from '@/app/config/api-routes';
import CommuneProjection from '@/types/projections/CommuneProjection';

class CommuneApi {
  async getListOfSupportedCommunes(): Promise<CommuneProjection[]> {
    return await get<CommuneProjection[]>(apiRoutes.communesByApiKey);
  }
}

export default new CommuneApi();
