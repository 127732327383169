import { GetterTree } from 'vuex';
import RootState from '@/types/store/RootState';
import DiscountState from '@/types/store/DiscountState';
import PriceDiscountOptionProjection from '@/types/projections/PriceDiscountOptionProjection';

export type getDiscountByIdFn = (id: number) => PriceDiscountOptionProjection | undefined;

const getters: GetterTree<DiscountState, RootState> = {
  getDiscountById(state): getDiscountByIdFn {
    return (id) => state.discounts[id];
  },
};

export default getters;
