import { GetterTree } from 'vuex';
import RootState from '@/types/store/RootState';
import CheckoutState from '@/types/store/CheckoutState';
import BookingPriceProjection from '@/types/checkout/BookingPriceProjection';
import CommuneProjection from '@/types/projections/CommuneProjection';
import Taxcard from '@/types/taxcard/Taxcard';
import LoginResponse from '@/types/checkout/LoginResponse';

const getters: GetterTree<CheckoutState, RootState> = {
  currentPriceProjection(state): BookingPriceProjection | null {
    return state.currentPriceProjection;
  },
  currentTicketDiscounts(state): Array<any> | null {
    return state.currentTicketDiscounts;
  },
  taxcards(state): Taxcard[] {
    return state.taxcards;
  },
  commune(state): CommuneProjection | null {
    return state.commune;
  },
  user(state): LoginResponse | null {
    return state.user;
  },
};

export default getters;
