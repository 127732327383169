import { MutationTree } from 'vuex';
import I18nState from '@/types/store/i18n/I18nState';

const mutations: MutationTree<I18nState> = {
  setLocale(state, locale: string | null) {
    state.locale = locale;
  },
  setTranslations(state, translations: { [path: string]: string }) {
    state.translations = translations;
  },
};

export default mutations;
