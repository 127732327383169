import StepsState from '@/types/store/StepsState';

const setDynamicStepValues = (state: StepsState, type: 'values' | 'initialValues', id: string, values: any): void => {
  const step = state[id];
  if (!step) {
    return;
  }
  state[id][type] = { ...values };
  // step[type] = values;
};

export default setDynamicStepValues;
