import { get, post } from '@/app/core/request';
import apiRoutes from '@/app/config/api-routes';
import PaymentMethod from '@/types/checkout/PaymentMethod';
import DirectPaymentData from '@/types/checkout/DirectPaymentData';

class CheckoutApi {
  async getPaymentOptions(communeId: number): Promise<PaymentMethod[]> {
    return (await get<string[]>(apiRoutes.checkoutListPaymentMethods.replace(':communeId', communeId.toString()))).map(
      (x) => new PaymentMethod(x)
    );
  }
  async directPayment(paymentData: DirectPaymentData): Promise<any> {
    return post<any>(apiRoutes.directPayment, paymentData);
  }
  async verifyPayment(
    checkoutId: string,
    checkoutResourcePath: string,
    orderId: string,
    paymentMethod: string
  ): Promise<string> {
    return post<any>(apiRoutes.verifyPayment, {
      checkoutId,
      checkoutResourcePath,
      orderId,
      paymentMethod,
    })
      .then((response) => {
        return response;
      })
      .then(({ message }) => message);
  }
  async freeCheckout(orderId: string): Promise<any> {
    return post<any>(apiRoutes.freeCheckout, { orderId });
  }
}

export default new CheckoutApi();
