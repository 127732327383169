import { ActionTree } from 'vuex';
import RootState from '@/types/store/RootState';
import StepsState from '@/types/store/StepsState';

const actions: ActionTree<StepsState, RootState> = {
  changeCurrentStep({ state, commit }, stepId) {
    commit('setCurrentStepId', stepId);
  },
};

export default actions;
