import { Getter } from 'vuex';
import StepsState from '@/types/store/StepsState';
import RootState from '@/types/store/RootState';
import stepValuesById from '@/store/modules/steps/getters/stepValuesById';

export type subStepValuesByIdFn = (parentId: string, id: string, index: number) => any;
const subStepValuesById: Getter<StepsState, RootState> = (...params) => (parentId: string, id: string, index: number) =>
  stepValuesById(...params)(parentId)[index][id]['values'];

export default subStepValuesById;
