import RootState from '@/types/store/RootState';
import { ActionTree, GetterTree } from 'vuex';
import WidgetApi from '@/app/api/WidgetApi';

export type fetchConfigurationFn = () => { offlinePaymentAllowed: boolean };

const actions: ActionTree<RootState, RootState> = {
  async fetchConfiguration(state): Promise<{ offlinePaymentAllowed: boolean }> {
    return WidgetApi.getConfiguration();
  },
};

export default actions;
