import { get } from '@/app/core/request';
import apiRoutes from '@/app/config/api-routes';

class CommuneApi {
  async getTranslations(communeId: number | string, locale: string): Promise<{ [path: string]: string }> {
    return get<{ [path: string]: string }>(
      apiRoutes.messages.replace(':communeId', communeId as string).replace(':locale', locale)
    );
  }
}

export default new CommuneApi();
