import { post, postRequest } from '@/app/core/request';
import apiRoutes from '@/app/config/api-routes';
import BookTaxcardRequest from '@/types/checkout/BookTaxcardRequest';
import BookTaxcardResponse from '@/types/checkout/BookTaxcardResponse';
import Taxcard from '@/types/taxcard/Taxcard';
import PriceDiscountOptionProjection from '@/types/projections/PriceDiscountOptionProjection';
import BookingPriceProjection from '@/types/checkout/BookingPriceProjection';

class BookingApi {
  async bookTaxcards(command: BookTaxcardRequest): Promise<BookTaxcardResponse> {
    return post<BookTaxcardResponse>(apiRoutes.bookTaxCards, command as any);
  }
  async fetchBookingPrice(command: BookTaxcardRequest): Promise<BookingPriceProjection> {
    const response = await postRequest(apiRoutes.fetchBookingPrice, command as any);
    return (await response.json()) as BookingPriceProjection;
  }
  async fetchCommuneDiscounts(communeId: number, taxcard: Taxcard): Promise<PriceDiscountOptionProjection[]> {
    return post<PriceDiscountOptionProjection[]>(
      apiRoutes.fetchCommuneDiscounts.replace(':communeId', communeId.toString()),
      taxcard as any
    );
  }
  async saveRegistrationForm(orderId: string, signature: any, passportNumber: string): Promise<boolean> {
    const response = await postRequest(apiRoutes.saveRegistrationForm.replace(':orderId', orderId), {
      signature,
      passportNumber,
    });
    return response.status === 200;
  }
}

export default new BookingApi();
