import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import mutations from './mutations';
import modules from './modules';
import getters from '@/store/getters';
import actions from '@/store/actions';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
  modules,
});
