import { get } from '@/app/core/request';
import ApiRoutes from '@/app/config/api-routes';

class WidgetApi {
  async getConfiguration(): Promise<{ offlinePaymentAllowed: boolean }> {
    return get<{ offlinePaymentAllowed: boolean }>(ApiRoutes.widgetConfiguration);
  }
}

export default new WidgetApi();
