
import VueClass, { CreateElement, VNode } from 'vue';
import { Component, Emit, Inject, Prop, Vue } from 'vue-property-decorator';
import VueIFrameConfig from '@/types/widget/VueIFrameConfig';
import WELCMpassRouter from '@/app/router/WELCMpassRouter';

/**
 * Source: https://jsfiddle.net/ohznser9/
 * by linusborg (https://forum.vuejs.org/u/linusborg/summary)
 * - This example was extended and put into nicer reusable component structure
 */
@Component({})
export default class VueIFrame extends Vue {
  @Inject()
  readonly vueIFrameConfig!: VueIFrameConfig;

  @Prop(Array)
  appClasses?: string[];

  @Prop(String)
  height!: string;

  @Prop(String)
  width!: string;

  iApp?: any;

  render(h: CreateElement): VNode {
    return h('iframe', {
      on: { load: this.onLoad },
      attrs: {
        width: this.width,
        height: this.height,
        style: 'border: 1px solid #b3bac8;',
      },
    });
  }

  beforeUpdate() {
    if (this.iApp) {
      //freezing to prevent unnessessary Reactifiation of vNodes
      this.iApp.children = Object.freeze(this.$slots.default);
    }
  }

  @Emit('loaded')
  onLoad() {
    this.renderChildren();
    return this.contentDocument;
  }

  mounted() {
    setTimeout(() => {
      this.contentDocument.addEventListener('mouseup', (e) => {
        window.document.dispatchEvent(new PointerEvent('pointerup', { cancelable: true, bubbles: true }));
      });
    }, 250);
  }

  renderChildren() {
    const children = this.$slots.default;
    const doc = this.contentDocument;

    const link = doc.createElement('link');
    link.href = `${process.env.VUE_APP_CDN_HOST}/booking-widget.css`;
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.media = 'screen,print';
    doc.head.appendChild(link);

    const el = document.createElement('DIV');
    doc.body.appendChild(el);

    const wrapperConfig: { class?: string[] } = {};

    if (this.appClasses) {
      wrapperConfig.class = this.appClasses;
    }

    const iApp = new VueClass({
      name: 'iApp',
      //freezing to prevent unnecessary Reactifiation of vNodes
      data: { children: Object.freeze(children) },
      render(h: (id: string, config: any, children: any) => any) {
        return h('div', wrapperConfig, (this as any).children);
      },
      ...this.vueIFrameConfig,
    });

    WELCMpassRouter.registerRouter(iApp.$router);
    iApp.$mount(el); // mount into iframe
    this.iApp = iApp; // cache instance for later updates
  }

  public get contentDocument(): HTMLDocument {
    const doc: HTMLIFrameElement = this.$el as HTMLIFrameElement;
    if (!doc.contentDocument) {
      throw new Error('Expected $el to be iframe');
    }
    return doc.contentDocument;
  }
}
