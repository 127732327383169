import StepDefinition from '@/types/steps/StepDefinition';
import { Getter } from 'vuex';
import StepsState from '@/types/store/StepsState';
import RootState from '@/types/store/RootState';
import { getStepById } from '@/app/steps/StepUtils';

export type currentStepFn = (id: string) => StepDefinition | undefined;
const currentStep: Getter<StepsState, RootState> = (state) => getStepById(state.currentStepId);

export default currentStep;
