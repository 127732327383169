import StepDefinition from '@/types/steps/StepDefinition';
import stepDefinitions from '@/app/steps';

export function getFlatSteps(steps: StepDefinition[] = stepDefinitions): StepDefinition[] {
  const flatSteps: StepDefinition[] = [];
  steps.forEach((step) => {
    flatSteps.push(step);
    if (step.steps) {
      step.steps.forEach((subStep) => {
        subStep.parentStep = step;
        flatSteps.push(subStep);
      });
    }
  });
  return flatSteps;
}

export const getStepById = (id: string): StepDefinition | undefined => getFlatSteps().find((step) => step.id === id);

export function getStepBeforeId(id: string): StepDefinition | undefined {
  const flatSteps = getFlatSteps();
  const index = flatSteps.findIndex((step) => {
    return step.id === id;
  });
  if (index === -1) {
    return;
  }
  const currentStep = flatSteps[index];
  const prevStep = flatSteps[index - 1];
  if (!prevStep) {
    return;
  }
  if (prevStep.parentStep && !currentStep.parentStep) {
    for (let i = index - 1; i > -1; i--) {
      const prevStep = flatSteps[i];
      if (!prevStep.parentStep) {
        return prevStep;
      }
    }
  }

  // if (currentStep.parentStep && !prevStep.parentStep) {
  //   // Step back to parent step if in sub steps loop
  //   // taxcardOptionData -> taxcard -> overview
  //   // not!: taxcardOptionData -> overview
  //   return currentStep.parentStep;
  // }
  // if (currentStep.steps) {
  //   // Step to next root level step (subStep is not the next actually)
  //   // commune -> taxcard -> overview
  //   // not!: commune -> taxcard -> taxcardGuestType -> ...
  //   const lastSubStep = currentStep.steps[currentStep.steps.length - 1];
  //   const lastSubStepIndex = flatSteps.findIndex(step => step.id === lastSubStep.id);
  //   return flatSteps[lastSubStepIndex - 1];
  // }
  return prevStep;
}

export function getStepAfterId(id: string): StepDefinition | undefined {
  const flatSteps = getFlatSteps();
  const index = flatSteps.findIndex((step) => {
    return step.id === id;
  });
  if (index === -1) {
    return;
  }
  const currentStep = flatSteps[index];
  const nextStep = flatSteps[index + 1];
  if (currentStep.parentStep && !nextStep.parentStep) {
    // Step back to parent step if in sub steps loop
    // taxcardOptionData -> taxcard -> overview
    // not!: taxcardOptionData -> overview
    return currentStep.parentStep;
  }
  if (currentStep.steps) {
    // Step to next root level step (subStep is not the next actually)
    // commune -> taxcard -> overview
    // not!: commune -> taxcard -> taxcardGuestType -> ...
    const lastSubStep = currentStep.steps[currentStep.steps.length - 1];
    const lastSubStepIndex = flatSteps.findIndex((step) => step.id === lastSubStep.id);
    return flatSteps[lastSubStepIndex + 1];
  }
  return nextStep;
}
