import { GetterTree } from 'vuex';
import RootState from '@/types/store/RootState';
import I18nState from '@/types/store/i18n/I18nState';

const getters: GetterTree<I18nState, RootState> = {
  locale(state): string | null {
    return state.locale;
  },
  availableLocales(state): { key: string; value: string }[] {
    return [
      { key: 'de', value: 'Deutsch' },
      { key: 'en', value: 'English' },
      { key: 'es', value: 'Español' },
      { key: 'it', value: 'Italiano' },
      { key: 'fr', value: 'Français' },
    ];
  },
  translations(state): { [path: string]: string } {
    return state.translations;
  },
};

export default getters;
