import { Module } from 'vuex';
import AccommodationState from '@/types/store/AccommodationState';
import RootState from '@/types/store/RootState';

import state from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const module: Module<AccommodationState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default module;
