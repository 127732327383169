import state from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { Module } from 'vuex';
import RootState from '@/types/store/RootState';
import I18nState from '@/types/store/i18n/I18nState';

const module: Module<I18nState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
export default module;
