// @ts-ignore
import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faAddressCard,
  faBookmark,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCity,
  faCoffee,
  faCog,
  faDog,
  faEllipsisH,
  faEnvelope,
  faExchangeAlt,
  faExclamationCircle,
  faFile,
  faSort,
  faFilter,
  faFingerprint,
  faGlobeAmericas,
  faHeart,
  faHome,
  faHourglassEnd,
  faHourglassStart,
  faIdBadge,
  faInfoCircle,
  faLock,
  faLockOpen,
  faMapMarkerAlt,
  faPhoneAlt,
  faPlus,
  faQrcode,
  faRoad,
  faSearch,
  faSignature,
  faStar,
  faStarOfLife,
  faStreetView,
  faTransgender,
  faTrash,
  faUndo,
  faUser,
  faUserSecret,
  faWheelchair,
  faCalendar,
  faMapPin,
  faClock,
  faPen,
  faBirthdayCake,
  faSun,
  faMoon,
  faAddressBook,
  faShieldAlt,
  faPercent,
} from '@fortawesome/free-solid-svg-icons';

import { faSquare as farSquare, faCheckSquare as farCheckSquare } from '@fortawesome/free-regular-svg-icons';

import { faGoogle } from '@fortawesome/free-brands-svg-icons';

library.add(faCalendar);
library.add(faAddressCard);
library.add(faBookmark);
library.add(faCheck);
library.add(faCheckCircle);
library.add(faChevronDown);
library.add(faClock);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faChevronUp);
library.add(faCity);
library.add(faCoffee);
library.add(faCog);
library.add(faDog);
library.add(faEllipsisH);
library.add(faEnvelope);
library.add(faExchangeAlt);
library.add(faExclamationCircle);
library.add(faFile);
library.add(faFilter);
library.add(faFingerprint);
library.add(faGlobeAmericas);
library.add(faGoogle);
library.add(faHeart);
library.add(faHome);
library.add(faHourglassEnd);
library.add(faHourglassStart);
library.add(faIdBadge);
library.add(faInfoCircle);
library.add(faLock);
library.add(faLockOpen);
library.add(faMapMarkerAlt);
library.add(faPhoneAlt);
library.add(faPlus);
library.add(faQrcode);
library.add(faRoad);
library.add(faSearch);
library.add(faSignature);
library.add(faStar);
library.add(faStarOfLife);
library.add(faStreetView);
library.add(faTransgender);
library.add(faTrash);
library.add(faUndo);
library.add(faSort);
library.add(faUser);
library.add(faUserSecret);
library.add(faWheelchair);
library.add(faMapPin);
library.add(farSquare);
library.add(farCheckSquare);
library.add(faPen);
library.add(faBirthdayCake);
library.add(faHourglassStart);
library.add(faEnvelope);
library.add(faSun);
library.add(faMoon);
library.add(faAddressBook);
library.add(faShieldAlt);
library.add(faPercent);

Vue.component('font-awesome-icon', FontAwesomeIcon);
