<template>
  <BeatLoader :loading="loading" :size="size" color="#1db2d1" style="margin-top: 1rem; text-align: center;" />
</template>

<script>
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
export default {
  components: {
    BeatLoader,
  },
  props: ['loading', 'size'],
};
</script>
