






import { Component, ProvideReactive, Vue, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import i18next, { TFunction } from 'i18next';
import moment from 'moment';

const I18nModule = namespace('i18n');

@Component
export default class I18nInitializer extends Vue {
  @I18nModule.Action
  private readonly fetchTranslations!: any;

  @I18nModule.Getter
  private readonly locale!: string | null;

  @I18nModule.Getter
  private readonly translations!: { [path: string]: string };

  @ProvideReactive('translate')
  public translate: any = () => {
    return;
  };

  @Prop(String)
  public language!: string | undefined;

  @I18nModule.Action
  private readonly switchLocale!: (x: string) => void;

  private async created(): Promise<void> {
    this.translate = await this.init();
    this.initialLanguage();
  }

  private initialLanguage() {
    if (this.language) {
      this.switchLocale(this.language);
    }
  }

  @Watch('locale')
  private async onLocaleChange(locale: string): Promise<void> {
    this.translate = await this.init();
  }

  private async init(): Promise<TFunction | (() => void)> {
    if (!this.locale) {
      return () => {
        return;
      };
    }

    await this.fetchTranslations();
    const translate = await i18next.init({
      lng: this.locale,
      interpolation: {
        format: function (value, format, lng) {
          if (format === 'uppercase') {
            return value.toUpperCase();
          }
          if (format === 'shortDate') {
            return moment(value).format(i18next.t('common.date.format.short'));
          }
          if (format === 'fullDate') {
            return moment(value).format(i18next.t('common.date.format.full'));
          }
          if (format === 'year') {
            return moment(value).format(i18next.t('common.date.format.year'));
          }
          if (format === 'price' && value) {
            const amount = value.toLocaleString(undefined, { minimumFractionDigits: 2 });
            return `${amount} €`;
          }
          return value;
        },
      },
      resources: { [this.locale]: { translation: this.translations } },
      parseMissingKeyHandler(key: string): any {
        return `!${key}!`;
      },
      keySeparator: false,
    });

    i18next.on('languageChanged', function (lng) {
      moment.locale(lng);
    });

    return translate;
  }
}
