import { get } from '@/app/core/request';
import apiRoutes from '@/app/config/api-routes';
import AccommodationProjection from '@/types/projections/AccommodationProjection';

class AccommodationApi {
  async search(communeId: string, queryString: string): Promise<AccommodationProjection[]> {
    return await get<AccommodationProjection[]>(
      apiRoutes.searchAccommodations.replace(':communeId', communeId).replace(':query', queryString)
    );
  }
}

export default new AccommodationApi();
