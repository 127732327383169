import StepDefinition from '@/types/steps/StepDefinition';
import { Getter } from 'vuex';
import StepsState from '@/types/store/StepsState';
import RootState from '@/types/store/RootState';
import { getStepAfterId } from '@/app/steps/StepUtils';

export type nextStepFn = (id: string) => StepDefinition | undefined;
const nextStep: Getter<StepsState, RootState> = (state) => getStepAfterId(state.currentStepId);

export default nextStep;
