import StepDefinition from '@/types/steps/StepDefinition';
import { RouteConfig } from 'vue-router';

const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const generateStepRoutes = (steps: StepDefinition[]): RouteConfig[] => {
  const routes: RouteConfig[] = [];

  steps.forEach((step) => {
    const id = step.id;
    routes.push({
      name: id,
      path: `/${id}`,
      component: () => import(`@/views/steps/${capitalize(id)}Step.vue`),
      props: (route) => ({
        step,
      }),
    });
    if (step.steps) {
      step.steps.forEach((subStep) => {
        const sid = subStep.id;
        routes.push({
          name: sid,
          path: `/${id}/${sid}`,
          component: () => import(`@/views/steps/${id}/${capitalize(sid)}Step.vue`),
          props: (route) => ({
            step: subStep,
          }),
        });
      });
    }
  });

  return routes;
};

export default generateStepRoutes;
