import { Getter } from 'vuex';
import StepsState from '@/types/store/StepsState';
import RootState from '@/types/store/RootState';
import Taxcard from '@/types/taxcard/Taxcard';
import moment from 'moment';

export type intermediateTaxcardsFn = () => Taxcard[];
const intermediateTaxcards: Getter<StepsState, RootState> = (state, getters) => {
  // get substeps from store
  const taxcardsFromStore = getters.stepValuesById('taxcard') || [];
  // return actual Taxcard objects
  return taxcardsFromStore.map((taxcardData: any, index: number) => {
    const taxcard: Taxcard = Object.values(taxcardData).reduce(
      (acc: Taxcard, data: any) => ({ ...acc, ...data.values } as Taxcard),
      {} as Taxcard
    );
    //TODO Why is cleanup necessary?
    taxcard.personCount = taxcard.persons ? taxcard.persons : 1;
    taxcard.isAnonymous = taxcard.anonymous ? true : false;
    // @ts-ignore
    taxcard.petCount = taxcard.pets;
    taxcard.stayBegin = moment(taxcard.stayBegin);
    taxcard.stayEnd = moment(taxcard.stayEnd);
    taxcard.isAnnual = false;
    taxcard.annualCardPhoto = '';
    taxcard.savePersonData = false;
    taxcard.guestType = taxcard.guestType ? taxcard.guestType : 'overnight';
    // @ts-ignore
    taxcard.birthday = moment(taxcard.birthday);
    // @ts-ignore
    taxcard.email = taxcard.mail;
    // @ts-ignore
    taxcard.cellphoneNumber = taxcard.phone;
    // @ts-ignore
    taxcard.addressZipCode = taxcard.addressZip;

    // @ts-ignore
    delete taxcard.pets;
    // @ts-ignore
    delete taxcard.persons;
    // @ts-ignore
    delete taxcard.anonymous;
    // @ts-ignore
    delete taxcard.mail;
    // @ts-ignore
    delete taxcard.phone;
    // @ts-ignore
    delete taxcard.addressZip;

    return taxcard;
  });
};
export default intermediateTaxcards;
